<template>
  <form action="#" @submit.prevent="handleSubmit">
    <div class="common-modal-headline">
      <h4 class="common-modal-title">
        {{ isNew ? $t(`page.${role}.add-new-user`) : $t(`page.${role}.edit-user`) }}
      </h4>
      <span class="material-symbols-rounded common-modal-close" @click="$emit('onClose')">
        close
      </span>
    </div>

    <hr class="my-3" />

    <b-alert variant="danger" dismissible class="mt-3" :show="Boolean(error)">{{ error }}</b-alert>

    <div class="row">
      <!-- left col -->
      <div class="col-md-6">

        <div class="form-group mb-3">
          <label :class="{ required: isNew }">{{ $t('form.login-email') }}</label>
          <div class="input-group input-group-merge">
            <input v-model="form.email" :readonly="!isNew" class="form-control"
              :placeholder="$t('form.login-email-placeholder')" :class="{
                'is-invalid': isNew && submitted && $v.form.email.$error,
              }" />
            <div v-if="isNew && submitted && $v.form.email.$error" class="invalid-feedback">
              <div v-if="!$v.form.email.required">
                {{ $t('form.login-email-req') }}
              </div>
              <div v-if="!$v.form.email.email">{{ $t('form.email-not-valid') }}</div>
            </div>
          </div>
        </div>

        <div class="form-group mb-3">
          <label :class="{ required: isNew }">{{ $t('form.password') }}</label>
          <div class="input-group input-group-merge">
            <input v-model="form.password" :type="showPassw ? 'text' : 'password'" class="form-control"
              :placeholder="$t('form.password-placeholder')" :class="{
                'is-invalid': isNew && submitted && $v.form.password.$error,
              }" />
            <div class="input-group-append" @click="showPassw = !showPassw" role="button">
              <div class="input-group-text">
                <span v-if="showPassw" class="material-symbols-rounded fz-18">visibility</span>
                <span v-else class="material-symbols-rounded fz-18">visibility_off</span>
              </div>
            </div>
            <div v-if="isNew && submitted && $v.form.password.$error" class="invalid-feedback">
              <div v-if="!$v.form.password.required">
                {{ $t('form.password-req') }}
              </div>
              <div v-if="!$v.form.password.minLength">{{ $t('form.password-min', { min: 8 }) }}</div>
              <div v-if="!$v.form.password.maxLength">{{ $t('form.password-max', { max: 30 }) }}</div>
              <div v-if="!$v.form.password.valid">
                {{ $t('form.password-not-valid') }}
              </div>
            </div>
          </div>
        </div>

        <!-- Select company only admin and if user is new -->
        <div v-if="role === 'admin' && isNew" class="form-group mb-0">
          <label class="required">{{ $t('form.company') }}</label>
          <div class="input-group input-group-merge">
            <AutocompleteSearchCompany v-model="form.company" :class-input="{
              'is-invalid': submitted && $v.form.company.$error,
            }" />
            <div v-if="submitted && $v.form.company.$error" class="invalid-feedback">
              <div v-if="!$v.form.company.required">
                {{ $t('form.company-req') }}
              </div>
            </div>
          </div>
        </div>

        <!-- <div v-if="isNew" class="form-group mb-0">
          <label class="required">Company</label>
          <div class="input-group input-group-merge">
            <b-form-select v-model="form.company_id" size="sm" class="form-control form-control-sm" :class="{
              'is-invalid': submitted && $v.form.company_id.$error,
            }">
              <b-form-select-option value="">Select</b-form-select-option>
              <b-form-select-option v-for="c in companies" :value="c.id" :key="c.id">{{ c.name
              }}</b-form-select-option>
            </b-form-select>
            <div v-if="submitted && $v.form.company_id.$error" class="invalid-feedback">
              <div v-if="!$v.form.company_id.required">
                Company is required
              </div>
            </div>
          </div>
        </div> -->

      </div>

      <!-- right col -->
      <div class="col-md-6">

        <div class="form-group mb-3">
          <label class="required">{{ $t('form.fio') }}</label>
          <div class="input-group input-group-merge">
            <input v-model="form.fio" class="form-control" :placeholder="$t('form.fio-placeholder')" :class="{
              'is-invalid': submitted && $v.form.fio.$error,
            }" />
            <div v-if="submitted && $v.form.fio.$error" class="invalid-feedback">
              <div v-if="!$v.form.fio.required">
                {{ $t('form.fio-req') }}
              </div>
              <div v-if="!$v.form.fio.minLength">{{ $t('form.fio-min', { min: 3 }) }}</div>
              <div v-if="!$v.form.fio.maxLength">{{ $t('form.fio-max', { max: 64 }) }}</div>
            </div>
          </div>
        </div>

        <div class="form-group mb-3">
          <label class="required">{{ $t('form.phone') }}</label>
          <div class="input-group input-group-merge">
            <input v-model="form.phone" class="form-control" :placeholder="$t('form.phone-placeholder')" :class="{
              'is-invalid': submitted && $v.form.phone.$error,
            }" />
            <div v-if="submitted && $v.form.phone.$error" class="invalid-feedback">
              <div v-if="!$v.form.phone.required">
                {{ $t('form.phone-req') }}
              </div>
              <div v-if="!$v.form.phone.minLength">{{ $t('form.phone-min', { min: 5 }) }}</div>
              <div v-if="!$v.form.phone.maxLength">{{ $t('form.phone-max', { max: 20 }) }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <hr class="mt-2 mb-1" />

    <div class="mt-3 text-center text-md-left">
      <button class="btn btn-primary" type="submit" :disabled="trySubmit">
        {{ isNew ? $t(`page.${role}.btn-add-user`) : $t('btn.save-changes') }}
      </button>
    </div>

    <!-- <pre>user: {{ user }}</pre> -->
    <!-- <pre>form: {{ form }}</pre> -->
  </form>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'
import AutocompleteSearchCompany from "@/components/autocomplete-search-company";

import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";

export default {
  props: {
    user: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    AutocompleteSearchCompany,
  },
  data() {
    return {
      error: "",

      form: {
        fio: "",
        phone: "",
        email: "",
        password: "",
        company: null,
      },

      showPassw: false,

      submitted: false,
      trySubmit: false,
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  created() {
    this.initForm();
  },
  computed: {
    ...mapState(useGlobalStore, ['userRoles']),
    role() {
      if (this.userRoles?.includes("a")) {
        return 'admin'
      }

      if (this.userRoles?.includes("c")) {
        return 'company'
      }

      return
    },
    isNew() {
      return !this.user?.id;
    },
    formRules() {
      let rules = {};

      rules.fio = {
        required,
        minLength: minLength(3),
        maxLength: maxLength(64),
      };

      rules.phone = {
        required,
        minLength: minLength(5),
        maxLength: maxLength(20),
      };

      if (this.isNew) {

        if (this.role === 'admin') {
          rules.company = {
            required,
          };
        }

        rules.email = {
          required,
          email,
        };

        rules.password = {
          required,
          // valid: function (value) {
          //   const containsUppercase = /[A-Z]/.test(value);
          //   const containsLowercase = /[a-z]/.test(value);
          //   const containsNumber = /[0-9]/.test(value);
          //   const containsSpecial = /[#?!@$%^&*-]/.test(value);
          //   return (
          //     containsUppercase &&
          //     containsLowercase &&
          //     containsNumber &&
          //     containsSpecial
          //   );
          // },
          minLength: minLength(8),
          maxLength: maxLength(30),
        };
      }

      return rules;
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ['editClient', 'createClient']),
    initForm() {
      if (this.isNew) {
        // new 
        // if new user has company data
        if (this.user?.company_id) {
          this.form = {
            company: {
              id: this.user?.company_id,
              name: this.user?.company_name || ""
            }
          }
        } else {
          this.form = {
            company: null
          }
        }
      } else {
        // edit
        this.form = {
          id: this.user.id,
          email: this.user.email,
          fio: this.user.fio,
          phone: this.user.phone,
          password: this.user.password,
        };
      }
    },
    async handleSubmit() {
      this.submitted = true;
      this.error = "";

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.trySubmit = true;
        let req = null

        if (this.isNew) {
          // console.log('Create new client, data: ', { ...this.form, company_id: this.form.company.id })   
          req = await this.createClient({ ...this.form, company_id: this.form.company.id })
        } else {
          // console.log('Edit client, data: ', this.form)
          req = await this.editClient({ ...this.form })
        }

        if (req?.data?.success) {
          this.$emit("onSubmit");
        } else {
          // console.log('login page, req: ', req)
          this.error = this.$t('msg.error')

          if (req.message && req.response?.data?.error) {
            this.error = req.message + ': ' + this.$t('msg.' + req.response.data.error)
          }
        }

        this.trySubmit = false;
      }
    },
  },
  watch: {},
};
</script>

<template>
  <Layout>

    <div class="row align-items-center text-center page-headline mb-3">
      <div class="col-xl-6 text-xl-left">
        <h1 class="display-3">{{ $t(`page.${role}.users`) }}</h1>
      </div>

      <div class="col-xl-6 text-xl-right">
        <router-link :to="`/${role}/leaderboard`" class="btn btn-purple mr-2 mt-2 mt-xl-0">
          <span class="material-symbols-rounded">
            leaderboard
          </span>
          {{ $t('leaderboard') }}
        </router-link>
        <button class="btn btn-primary mt-2 mt-xl-0" @click.prevent="addUser">
          <span class="material-symbols-rounded mr-2"> add </span>{{ $t(`page.${role}.btn-add-user`) }}
        </button>
      </div>
    </div>

    <div v-if="loading">
      <div class="text-center">
        <b-spinner variant="primary" />
      </div>
    </div>
    <div v-else>
      <b-alert v-if="error" variant="danger" class="mt-3" dismissible :show="Boolean(error)">{{ error }}</b-alert>
      <template v-else>

        <div class="card">
          <div class="card-body">

            <div class="search-field mb-2">
              <b-form-input v-model="search" :placeholder="$t('user.search-placeholder')" class="search-field-input"
                @keyup.enter="searchStart" />
              <button v-if="search.length >= 3" class="search-field-btn search-field-btn-reset" @click="searchReset"
                :title="$t('btn.reset')">
                <span class="material-symbols-rounded search-field-ico">
                  close
                </span>
              </button>
              <button class="search-field-btn" @click="searchStart" :title="$t('btn.search')">
                <span class="material-symbols-rounded search-field-ico">
                  search
                </span>
              </button>
            </div>

            <!-- Admin filters -->
            <div v-if="role === 'admin'" class="row mb-md-2 text-center table-filters">
              <div class="col-12 col-xl-auto ml-xl-0 text-xl-left">
                <div class="table-filters-label d-block d-md-inline-flex align-items-center flex-wrap mr-2">
                  <label class="d-inline-flex align-items-center mr-2">
                    <span class="text-nowrap mr-2">{{ $t('form.filter-by-company') }}:</span>
                    <AutocompleteSearchCompany v-model="company" :key="refreshKey" />
                  </label>
                </div>
              </div>
              <div class="col-12 col-xl-auto m-xl-auto text-xl-center">
                <div class="table-filters-label d-block d-md-inline-flex align-items-center flex-wrap mr-2">
                  <label class="d-inline-flex align-items-center mr-2">
                    <span class="text-nowrap">{{ $t('form.filter-by-block') }}:</span>
                    <b-form-select v-model="block" size="sm" class="form-control form-control-sm ml-2">
                      <b-form-select-option value="" selected>{{ $t('form.state-all') }}</b-form-select-option>
                      <b-form-select-option v-for="b in blocks" :value="b.id" :key="b.id">{{ b.name
                      }}</b-form-select-option>
                    </b-form-select>
                  </label>
                </div>
              </div>
              <div class="col-12 col-xl-auto mr-xl-0 text-xl-right">
                <div id="tickets-table_filter" class="dataTables_filter">
                  <label class="d-inline-flex align-items-center">
                    <span class="text-nowrap">{{ $t('form.quantity') }}:</span>
                    <b-form-select v-model="per_page" size="sm" :options="pageOptions"
                      class="form-control form-control-sm ml-2"></b-form-select>
                  </label>
                </div>
              </div>
            </div>

            <!-- Company filters -->
            <div v-if="role === 'company'" class="row mb-md-2 text-center table-filters">
              <div class="col-lg-6 col-md-auto text-md-left">
                <div class="table-filters-label d-block d-md-inline-flex align-items-center flex-wrap mr-2">
                  <label class="d-inline-flex align-items-center mr-2">
                    <span class="text-nowrap">{{ $t('form.filter-by-block') }}:</span>
                    <b-form-select v-model="block" size="sm" class="form-control form-control-sm ml-2">
                      <b-form-select-option value="" selected>{{ $t('form.state-all') }}</b-form-select-option>
                      <b-form-select-option v-for="b in blocks" :value="b.id" :key="b.id">{{ b.name
                      }}</b-form-select-option>
                    </b-form-select>
                  </label>
                </div>
              </div>
              <div class="col-lg-6 col-md-auto ml-auto">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <span class="text-nowrap">{{ $t('form.quantity') }}:</span>
                    <b-form-select v-model="per_page" size="sm" :options="pageOptions"
                      class="form-control form-control-sm ml-2"></b-form-select>
                  </label>
                </div>
              </div>
            </div>

            <div class="table-responsive mb-0">

              <!-- <div>
                <pre>companies: {{ companies }}</pre>
              </div> -->
              <!-- <div>
                <pre>page: {{ page }}</pre>
              </div> -->
              <!-- <div>
                <pre>per_page: {{ per_page }}</pre>
              </div> -->
              <!-- <div>
                <pre>tableData: {{ tableData }}</pre>
              </div> -->
              <!-- <div>
                <pre>sortBy: {{ sortBy }}</pre>
              </div> -->
              <!-- <div>
                <pre>sortDesc: {{ sortDesc }}</pre>
              </div> -->

              <!-- :no-local-sorting="true" -->
              <b-table :items="tableData" :fields="tableFields" responsive="sm" :per-page="per_page" :filter="filter"
                :filter-included-fields="filterOn" show-empty :tbody-tr-class="rowClass" :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc" :busy.sync="isBusy" @head-clicked="headClicked">

                <template #head(id)="data">
                  {{ $t('table.id') }}
                </template>
                <template #head(fio)="data">
                  {{ $t('table.fio') }}
                </template>
                <template #head(company_name)="data">
                  {{ $t('table.company_name') }}
                </template>
                <template #head(phone)="data">
                  {{ $t('table.phone') }}
                </template>
                <template #head(email)="data">
                  {{ $t('table.login') }}
                </template>

                <template #cell(fio)="data">
                  <router-link :to="`/${role}/users/${data.item.id}`">{{ data.item.fio }}</router-link>
                </template>

                <template #cell(action)="data">
                  <a href="#" @click.prevent="handleEditClient(data.item)" class="action-edit"
                    :title="$t('btn.edit')"><span class="material-symbols-rounded fz-24 v-middle mx-1">
                      edit
                    </span></a>
                  <a v-if="!data.item.block" href="#" @click.prevent="confirmBlock(data.item)" class="action-block"
                    :title="$t('btn.block')"><span class="material-symbols-rounded fz-28 v-middle mx-1">
                      Block
                    </span></a>
                  <a v-else href="#" @click.prevent="confirmUnBlock(data.item)" class="action-unblock"
                    :title="$t('btn.unblock')"><span class="material-symbols-rounded fz-28 v-middle mx-1">
                      Block
                    </span></a>
                </template>

                <template #empty>
                  <h4 class="text-center">
                    {{ $t('table.no-data') }}
                  </h4>
                </template>
              </b-table>
            </div>
            <div class="row mb-3">
              <div class="col-12 text-center">
                <div class="dataTables_paginate paging_simple_numbers">
                  <b-pagination v-model="page" :total-rows="totalRows" :per-page="per_page"></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <b-modal v-model="showModal" size="lg" hide-header hide-footer centered>
      <formUser :user="user" @onSubmit="submitForm" @onClose="showModal = false" />
    </b-modal>

  </Layout>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'
import Layout from "@/views/layouts/main";
import formUser from "@/components/form-user";
import AutocompleteSearchCompany from "@/components/autocomplete-search-company";

export default {
  components: {
    Layout,
    formUser,
    AutocompleteSearchCompany,
  },
  metaInfo() {
    return {
      title: this.$t('app.title') + " | " + this.$t(`page.${this.role}.users`),
    };
  },
  data() {
    return {
      loading: false,
      error: "",
      wait: false,

      search: "",
      block: "",

      company: null,
      companies: [],

      tableData: [],
      totalRows: 0,
      page: 1,
      pageOptions: [25, 50, 100],
      per_page: 25,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      isBusy: false,
      _helper: "",

      showModal: false,
      user: "",

      refreshKey: 0,
    };
  },
  created() {
    this.paramFromQuery();
    this.loadData();
  },
  computed: {
    // ...mapState(useGlobalStore, ['userRoles']),
    ...mapState(useGlobalStore, {
      owner: 'user',
      userRoles: 'userRoles',
    }),
    role() {
      if (this.userRoles?.includes("a")) {
        return 'admin'
      }

      if (this.userRoles?.includes("c")) {
        return 'company'
      }

      return
    },
    blocks() {
      return [
        {
          id: 0,
          name: this.$t('form.state-unblock')
        },
        {
          id: 1,
          name: this.$t('form.state-block')
        }
      ]
    },
    tableFields() {
      let fields = [
        {
          key: "id",
          // sortable: true,
          class: "cell-id",
        },
        {
          key: "fio",
          // sortable: true,
          class: "cell-user",
        },
        {
          key: "company_name",
        },
        {
          key: "phone",
        },
        {
          key: "email",
        },
        {
          key: "action",
          label: "",
          class: "cell-action",
        },
      ]

      // if company remove - field company_name
      if (this.role === 'company') {
        return fields.filter(field => field.key !== 'company_name')
      }

      return fields
    },
    sortable() {
      return this.tableFields?.map(i => i.sortable && i.key) || []
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ['getCompanyList', 'getClientList', 'editClient']),
    async setQuery(newQuery, toHistory = false) {
      const query = Object.assign({}, { ...this.$route.query, ...newQuery });

      // delete empty fields
      Object.keys(query).forEach(key => {
        if (query[key] === undefined || query[key] === null || query[key] === '') {
          delete query[key];
        }
      })

      if (toHistory) {
        await this.$router.push({ query })
      } else {
        await this.$router.replace({ query }).catch(err => { }) // or t: new Date().getTime(), - timestamp
      }
    },
    paramFromQuery() {
      this.search = (this.$route.query?.search?.length >= 3) ? this.$route.query.search : ""
      this.block = this.$route.query?.block || ""
      this.page = Number(this.$route.query?.page) || 1
      this.per_page = Number(this.$route.query?.per_page) || 25

      // init company
      // this.company_id = Number(this.$route.query?.company_id) || "" // simple select case   
      const company_id = Number(this.$route.query?.company_id) || ""

      if (company_id || company_id === 0) {
        this.company = this.companies.find(c => c.id === company_id)
      } else {
        this.company = null
      }

      this.sortBy = this.$route.query?.sort || ""
      if (this.sortBy) {
        if (this.$route.query?.sortd === 'asc') {
          this.sortDesc = false
        }
        if (this.$route.query?.sortd === 'desc') {
          this.sortDesc = true
        }
      }

      this.refreshKey += 1
    },
    async loadData() {
      try {
        this.loading = true;
        this.error = "";

        if (this.role === 'admin') {
          const response = await this.getCompanyList({ per_page: 100, page: 1 });
          this.companies = response?.data?.list?.list ?? []
        }

        await this.loadTableData(false);
      } catch (error) {
        console.log("loadData Error: ", error);
        this.error = error
      } finally {
        this.loading = false;
      }
    },
    async loadTableData(silent = true) {
      if (this.wait) {
        return
      }

      this.wait = true
      this.isBusy = true

      // console.log('loadTableData...')
      this.paramFromQuery();

      try {
        if (!silent) {
          this.loading = true;
        }
        this.error = "";

        // const response = await this.getClientList();
        const company_id = Number(this.company?.id) || ""

        const params = {
          per_page: this.per_page,
          page: this.page,
          block: this.block,
          qname: this.search,
          sort: this.sortBy,
          sortd: this.sortBy ? this.sortDirection : "",
          company_id
        }
        // console.log('params: ', params)

        const response = await this.getClientList(params);
        this.tableData = response.data.list.list || [];
        this.totalRows = response.data.list.total;

      } catch (error) {
        console.log("loadTableData Error: ", error);
        this.error = error;
      } finally {
        this.loading = false;
        this.wait = false
        this.isBusy = false
      }
    },
    confirmBlock(user) {
      this.$bvModal
        .msgBoxConfirm(
          `Do you want to BLOCK user: ${user.fio} ?`,
          {
            title: "Block confirmation",
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: this.$t('btn.yes'),
            cancelTitle: this.$t('btn.cancel'),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (sure) => {
          if (sure) {
            await this.editClient({
              id: user.id,
              block: 1 // 1 - block
            })
            this.loadTableData()
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    confirmUnBlock(user) {
      this.$bvModal
        .msgBoxConfirm(
          `Do you want to UNBLOCK user: ${user.fio} ?`,
          {
            title: "Unblock confirmation",
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: this.$t('btn.yes'),
            cancelTitle: this.$t('btn.cancel'),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (sure) => {
          if (sure) {
            await this.editClient({
              id: user.id,
              block: 0 // 0 - unblock
            })
            this.loadTableData()
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    handleEditClient(user) {
      this.user = { ...user };
      this.showModal = true;
    },
    addUser() {
      if (this.role === 'admin') {
        this.user = {
          company_id: this.company?.id || "",
          company_name: this.company?.name || ""
        };
      }

      if (this.role === 'company') {
        // owner === company
        this.user = {
          company_id: this.owner.id,
          company_name: this.owner.name
        };
      }

      this.showModal = true;
    },
    submitForm() {
      this.loadTableData(false);
      this.showModal = false;
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.block !== 0) return 'table-warning'
    },
    searchStart() {
      if (this.search.length >= 3 || this.search === "") {
        this.setQuery({ page: 1, search: this.search }, true)
      }
    },
    searchReset() {
      this.search = ""
      this.setQuery({ page: 1, search: this.search }, true)
    },
    headClicked(column) {
      // console.log('headClicked, column: ', column)
      if (this.sortable.includes(column)) {
        // console.log('1 sortable...')

        if (column === this._helper) {
          if (this.sortDesc) {
            this.sortBy = "";
            this._helper = "";
          } else {
            this.sortBy = column;
            this._helper = column;
          }
        } else {
          this.sortDesc = false;
          this.sortBy = column;
          this._helper = column;
        }

      } else {
        // console.log('2 NOT sortable...')
        this.sortBy = "";
        this._helper = "";
      }
    },
    updateSort() {
      if (this.sortBy) {
        this.setQuery({ page: 1, sort: this.sortBy, sortd: this.sortDesc ? 'desc' : 'asc' }, true)
      } else {
        this.setQuery({ page: 1, sort: "", sortd: "" }, true)
      }
    },
  },
  watch: {
    // watch query like nuxt watchQuery
    '$route.query'(q, old) {
      if (
        ((q.search || old.search) && (q.search !== old.search)) ||
        ((q.page || old.page) && (q.page !== old.page)) ||
        ((q.per_page || old.per_page) && (q.per_page !== old.per_page)) ||
        (this.role === 'admin' && (q.company_id || old.company_id) && (q.company_id !== old.company_id)) ||
        ((q.block || old.block) && (q.block !== old.block)) ||
        ((q.sort || old.sort) && (q.sort !== old.sort)) ||
        ((q.sortd || old.sortd) && (q.sortd !== old.sortd))
        // ((q.categ_id?.length !== old.categ_id?.length)) || // # compare arrays
      ) {
        this.loadTableData()
      }
    },
    page(page) {
      this.setQuery({ page }, true)
    },
    per_page(per_page) {
      this.setQuery({ page: 1, per_page }, true)
    },
    'company.id'(company_id) {
      this.setQuery({ page: 1, company_id }, true)
    },
    sortBy() {
      this.updateSort()
    },
    sortDesc() {
      this.updateSort()
    },
    block(block) {
      this.setQuery({ page: 1, block }, true)
    },
  },
};
</script>
<style scoped lang="scss"></style>
